<template>
  <v-app>    
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="scss" scoped>
  .v-main {
    background-color: #f7f7f7;
  }
  header {
    position: fixed !important;
  }
  .container {
    padding: 0px;
  }
</style>