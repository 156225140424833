import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '@/services/authService'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!AuthService.isLogged()) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (AuthService.isLogged()) {
    next()
    return
  }
  next('/login')
}

const ifAuthenticatedAndAdmin = (to, from, next) => {
  if (AuthService.isLogged()) {
    next()
    return
  }
  next('/login')
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/Admin.vue'),
    beforeEnter: ifAuthenticatedAndAdmin
  },
  {
    path: '/perfil',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/perfil/contrasena',
    name: 'ChangeFirstPassword',
    component: () => import('@/views/ChangeFirstPassword.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cambiar-contrasena',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/recuperar-contrasena',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/condiciones-de-la-promocion',
    name: 'Condiciones de la promoción',
    component: () => import('@/views/Condiciones.vue'),
    beforeEnter: ifNotAuthenticated
  },  
  {
    path: '/registro',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: ifNotAuthenticated
  }
]

const router = new VueRouter({
  routes
})

export default router
