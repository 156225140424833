import router from '@/router/index'

export default {
    setToken(token) {
        localStorage.setItem('token', token)
    },
    getToken() {
        return localStorage.getItem('token')
    },
    setRefresh(token) {
        localStorage.setItem('token-refresh', token)
    },
    getRefresh() {
        return localStorage.getItem('token-refresh')
    },
    setUser(user) {
        localStorage.setItem('user', JSON.stringify(user))
    },
    getUser() {
        return JSON.parse(localStorage.getItem('user'))
    },
    logout() {
        localStorage.removeItem('token')
        router.go('/login')
    },
    isLogged() {
        return localStorage.getItem('token') != null
    }
  }