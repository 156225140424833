import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


// Translation provided by Vuetify (typescript)
import es from 'vuetify/es5/locale/es'

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#109bc5'
            }
        }
    },
    lang: {
        locales: { es},
        current: 'es',
      },
});
